import React from "react";
import About from "./About";
import Step from "./Step";
import FooterSection from "./FooterSection";

const Content = () => {
  return (
    <>
      <section class="text-gray-400 body-font bg-gray-900">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h2 class="text-xs text-green-700 tracking-widest font-medium title-font mb-1">
              "Scan, Plan, Dine Divine!"
            </h2>
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">
              Discover a Smarter Way to Eat with Our Food Recommendation App
            </h1>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
              Unlock the full potential of your kitchen with our cutting-edge
              food recommendation app. By simply scanning the contents of your
              fridge and grocery items, you can enjoy a range of services
              designed to make meal planning, shopping, and healthy eating
              easier than ever before. Explore our innovative features below:
            </p>
          </div>
          <div class="flex flex-wrap">
            <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-800">
              <h2 class="text-lg sm:text-xl text-white font-medium title-font mb-2">
                Fridge and Pantry Scanning
              </h2>
              <p class="leading-relaxed text-base mb-4">
                Scan and catalog all items in your fridge and pantry using your
                smartphone camera. Utilize barcode scanning for quick and
                accurate product identification.
              </p>
            </div>
            <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-800">
              <h2 class="text-lg sm:text-xl text-white font-medium title-font mb-2">
                Grocery Scanning
              </h2>
              <p class="leading-relaxed text-base mb-4">
                Scan grocery receipts or items to automatically update your
                shopping list and pantry inventory. Provide detailed nutritional
                information and dietary guidelines for scanned grocery items.
              </p>
            </div>
            <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-800">
              <h2 class="text-lg sm:text-xl text-white font-medium title-font mb-2">
                Recipe Suggestions
              </h2>
              <p class="leading-relaxed text-base mb-4">
                Generate recipe suggestions based on the ingredients available
                in your fridge and pantry. Dietary Preferences: Customize recipe
                recommendations according to dietary restrictions and
                preferences.
              </p>
            </div>
            <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-800">
              <h2 class="text-lg sm:text-xl text-white font-medium title-font mb-2">
                Health and Nutrition Tracking
              </h2>
              <p class="leading-relaxed text-base mb-4">
                Track daily calorie intake based on consumed recipes and
                ingredients. Set and monitor nutritional goals such as protein,
                carbohydrate, and fat intake. Receive recommendations to improve
                your diet.
              </p>
            </div>
          </div>
        </div>
      </section>
      <About />
      <Step />
      <FooterSection />
    </>
  );
};

export default Content;

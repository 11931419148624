import React from "react";

const Success = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        id="CheckCircle"
        className="h-22 w-36 text-green-500"
      >
        <rect width="256" height="256" fill="none"></rect>
        <path
          d="M128,24A104,104,0,1,0,232,128,104.12041,104.12041,0,0,0,128,24Zm49.53125,85.78906-58.67187,56a8.02441,8.02441,0,0,1-11.0625,0l-29.32813-28a8.00675,8.00675,0,0,1,11.0625-11.57812l23.79687,22.72656,53.14063-50.72656a8.00675,8.00675,0,0,1,11.0625,11.57812Z"
          fill="#34a853"
          class="color000000 svgShape"
        ></path>
      </svg>
      {/* className="h-16 w-16 text-green-500" */}
      <h1 className="text-3xl text-green-500 font-semibold my-4">Success!</h1>
      <p className="text-gray-700 text-lg">
        Thank you for reaching out! Our team will review your inquiry and
        connect with you shortly.
      </p>
    </div>
  );
};

export default Success;

import React from "react";

const FooterSection = () => {
  return (
    <section id="going-up" class="bg-gray-100 dark:bg-gray-800lg:px-16 mb-20">
      <div class="w-full flex flex-col text-center lg:text-left lg:flex-row items-center justify-center lg:justify-around  mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
        <h2 class="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
          <span class="block"> Elevate your meals effortlessly</span>
          <span class="block text-green-400">
            {" "}
            Start scanning and savoring now!
          </span>
        </h2>
        <div class="lg:mt-0 mt-10 lg:flex-shrink-0">
          <div class=" inline-flex rounded-md shadow">
            <a
              class="py-4 px-6  bg-green-700 hover:bg-green-500 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              href="contact"
            >
              Coming Soon
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterSection;

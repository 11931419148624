import React from "react";

const About = () => {
  return (
    <section class="text-gray-600 body-font overflow-hidden">
      <div class="container px-5 py-24 mx-auto">
        <div class="lg:w-4/5 mx-auto flex flex-wrap">
          <img
            class="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
            src="https://t3.ftcdn.net/jpg/06/33/25/04/240_F_633250476_fJwdBO1p3o7fCl1IgVYVR7jTLLVRTDAp.jpg"
            alt="Khanabook.ai"
          />
          <div class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
            <h1 class="text-gray-900 text-3xl title-font font-medium mb-1">
              About Khanabook
            </h1>
            <h2 class="text-sm title-font text-gray-500 tracking-widest">
              KhanaBook: AI-Powered Food Recommendation App
            </h2>
            <div class="flex mb-4"></div>
            <p class="leading-relaxed">
              Welcome to KhanaBook, the revolutionary food recommendation app
              that transforms your kitchen experience. Using advanced AI
              technology, KhanaBook scans the contents of your fridge and
              pantry, offering personalized recipe suggestions based on the
              ingredients you already have. Say goodbye to food waste and hello
              to culinary creativity!
            </p>
            <div class="flex mt-6 items-center pb-5 border-b-2 border-gray-100 mb-5">
              <h2 class="text-xl title-font text-gray-500 tracking-widest">
                Our Mission
              </h2>
            </div>
            <div class="flex">
              At KhanaBook, our mission is to make meal planning effortless and
              enjoyable.
              <br /> We aim to help you: Maximize Your Ingredients: Use what's
              already in your fridge to create delicious and nutritious meals.
              <br />
              Reduce Food Waste: Make the most of your groceries, ensuring
              nothing goes to waste.
              <br /> Save Time: No more last-minute trips to the grocery store
              or hours spent deciding what to cook.
              <br /> Eat Healthier: Receive tailored suggestions that match your
              dietary preferences and nutritional goals.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
